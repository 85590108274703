<template>
  <div>
    <el-card>
      <div class="title">分享员信息</div>
      <div class="flexRow">
        <img :src="peoplemsg.WxHeadUrl?peoplemsg.WxHeadUrl:defaultheader" style="width:50px;height:50px;border-radius:2px" alt="">
        <div class="defaultfont" style="margin-left:5px">
          <span style="color:#f56c6c" v-if="peoplemsg.IdentityTypeDisplay">[{{peoplemsg.IdentityTypeDisplay}}]</span>
          {{peoplemsg.WxNickname}}
          <span v-if="peoplemsg.Name">（{{peoplemsg.Name}}）</span>
        </div>
        <div class="defaultfont" style="margin-left:10%">手机号:{{peoplemsg.Phone}}</div>
        <div class="defaultfont" style="margin-left:10%">绑定时间:{{peoplemsg.BindTime}}</div>
      </div>
    </el-card>
    <el-card style="margin-top:10px">
      <div class="flexRow">
        <div class="flexRow">
          <div class="label-font">关键字：</div>
          <el-input v-model="keywords" placeholder="直播间名称" style="width:200px"></el-input>
        </div>
        <el-button type="primary" style="margin-left:10px" @click="toquery">查询</el-button>
        <div class="grayfont flexRow" style="margin-left:10px">
          <i class="el-icon-warning" style="font-size:16px;margin-right:5px"></i>
          <span class="">直播间带货数据更新至昨日，每日凌晨四点更新</span>
        </div>
      </div>
      <div style="margin-top:10px;overflow-x:auto" v-loading="tableloading">
        <el-card v-for="(v,i) in tablelist" :key="i" class="sharemembercard" style="margin-top:20px">
          <div slot="header" class="header flexRow" style="justify-content: space-between;">
            <div class="flexRow" style="flex:1">
              <div class="defaultfont" style="margin-left:10px">直播间：{{v.LiveNickname}}</div>
              <div class="defaultfont" style="margin-left:10%">开播时间：{{v.LiveStartTime}}</div>
              <div class="defaultfont" style="margin-left:10%" v-if="v.LiveStatus==0">结束时间：{{v.LiveEndTime}}</div>
            </div>
            <div class="flexRow">
              <div :class="v.LiveStatus==0?'grayround':'greenround'"></div>
              <div class="defaultfont" :style="{'color':v.LiveStatus==0?'#999999':'#67C23A','margin':'0px 10px'}">
                {{v.LiveStatusDisplay}}
              </div>
            </div>
          </div>
          <div class="flexRow" style="justify-content: space-between;">
            <div class="blockdemo">
              <div class="number">￥{{v.OrderProductMoney.toFixed(2)}}</div>
              <div class="font">带货总额(元)</div>
            </div>
            <div class="blockdemo">
              <div class="number">￥{{v.AwardMoney.toFixed(2)}}</div>
              <div class="font">带货收益(元)</div>
            </div>
            <div class="blockdemo">
              <div class="number">{{v.OrderCount}}</div>
              <div class="font">带货订单数</div>
            </div>
            <div class="blockdemo">
              <div class="number">{{v.OrderMemberCount}}</div>
              <div class="font">带货用户数</div>
            </div>
            <div class="blockdemo">
              <div class="number">￥{{v.RefundProductMoney.toFixed(2)}}</div>
              <div class="font">退款总额(元)</div>
            </div>
            <el-button type="text" @click="lookdetail(v)">订单明细</el-button>
          </div>
        </el-card>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="dialogShow" title="直播间订单明细" width="1100px" @close="closedialog">
      <div class="flexRow">
        <div class="flexRow">
          <div class="label-font">关键字：</div>
          <el-input v-model="serialnum" placeholder="订单编号" style="width:200px"></el-input>
        </div>
        <div class="flexRow" style="margin-left:10px">
          <div class="label-font">支付结果：</div>
          <el-select v-model="paystate">
            <el-option v-for="(v,i) in statelist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button type="primary" style="margin-left:10px" @click="search">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="dellist" v-loading="delloading" max-height="500" @close="closedialog">
          <el-table-column prop="" label="订单编号" min-width="250">
            <template slot-scope="scope">
              <el-button type="text" @click="lookdel(scope.row)">{{scope.row.OrderNo}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="" label="订单实付(元)" width="120">
            <template slot="header" slot-scope="scope">
              <div class="flexRow">
                <div style="margin-right:5px">订单实付(元)</div>
                <el-popover placement="top" trigger="hover">
                  <div style="max-width:300px">不包含运费，不剔除已退款金额</div>
                  <i class="el-icon-warning" slot="reference"></i>
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              ￥{{scope.row.OrderProductMoney.toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column prop="" label="带货收益(元)" width="120">
            <template slot="header" slot-scope="scope">
              <div class="flexRow">
                <div style="margin-right:5px">带货收益(元)</div>
                <el-popover placement="top" trigger="hover">
                 <div style="max-width:300px">该笔订单可获得的推手收益或员工提成，剔除订单完成前因退款扣减的收益或提成</div>
                  <i class="el-icon-warning" slot="reference"></i>
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              ￥{{scope.row.AwardMoney.toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="下单时间" width="150"></el-table-column>
          <el-table-column prop="PayTimeDisplay" label="支付时间" width="150"></el-table-column>
          <el-table-column prop="" label="退款金额(元)" width="120">
            <template slot="header" slot-scope="scope">
              <div class="flexRow">
                <div style="margin-right:5px">退款金额(元)</div>
                <el-popover placement="top" trigger="hover">
                  <div style="max-width:300px">订单已退款成功金额</div>
                  <i class="el-icon-warning" slot="reference"></i>
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              ￥{{scope.row.RefundProductMoney.toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column prop="OrderStateDisplay" label="订单状态"></el-table-column>
        </el-table>
        <div style="margin-top:10px;text-align:right" v-if="total2">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize2"
            layout="total, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import config from "@/config"
import {
  videoLiveSharerliveList,
  videoLiveSharerliveorderlist
}from "@/api/sv1.0.0"
export default {
  data () {
    return {
      defaultheader:config.DEFAULT_HEADER,
      keywords:'',
      peoplemsg:{},
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},
      dialogShow:false,
      serialnum:'',
      paystate:1,
      statelist:[
        {value:1,label:'已支付'},
        {value:0,label:'未支付'},
      ],
      dellist:[],
      delloading:false,
      currentPage2:1,
      pagesize2:10,
      total2:0,
    }
  },
  created () {
    this.gettablelist()
  },
  methods: {
    toquery(){
      this.currentPage = 1
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Id:this.$route.query.Id,
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await videoLiveSharerliveList(data)
        if(res.IsSuccess){
          this.peoplemsg = res.Result
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    lookdetail(row){
      this.dialogShow = true
      this.rowmsg = row
      this.gettablelist2()
    },
    search(){
      this.currentPage2 = 1
      this.gettablelist2()
    },
    handleSizeChange2(e){
      this.currentPage2 = 1
      this.pagesize2 = e
      this.gettablelist2()
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.gettablelist2()
    },
    async gettablelist2(){
      try{
        this.delloading = true
        let data = {
          Id:this.rowmsg.Id,
          Keywords:this.serialnum,
          PayState:this.paystate,
          Skip:(this.currentPage2-1)*this.pagesize2,
          Take:this.pagesize2
        }
        let res = await videoLiveSharerliveorderlist(data)
        if(res.IsSuccess){
          this.dellist = res.Result.Results
          this.total2 = res.Result.Total
        }
      }finally{
        this.delloading = false
      }
    },
    closedialog(){
      this.currentPage2 = 1
      this.pagesize2 = 10
      this.serialnum = ''
      this.paystate = 1
      this.dialogShow = false
    },
    lookdel(row){
      this.$router.push({
        path:'/order/orderDetailpage',
        query:{
          Id:row.OrderNo
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.title{
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  color: #303133;
}
.flexRow{
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.defaultfont{
  font-size: 14px;
  line-height: 1.5;
  color: #606266;
  white-space: nowrap;
}
.label-font{
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
  white-space: nowrap;
  font-weight: bold;
  min-width: 70px;
  text-align: right;
}
.greenround{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #67C23A;
}
.grayround{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #999999;
}
.blockdemo{
  padding: 10px;
  .number{
    font-size: 18px;
    font-weight: bold;
    color: #303133;
    white-space: nowrap;
    line-height: 1.5;
  }
  .font{
    font-size: 14px;
    color: #606266;
    white-space: nowrap;
    line-height: 1.5;
    margin-top:5px
  }
}
</style>
<style lang="less">
.sharemembercard{
  // border:1px solid black;
  min-width: 1000px;
  .el-card__header{
    background: rgb(248,248,249);
  }
}
</style>